<template>
    <div class="main">
        <Title>公司福利</Title>
        <!-- <ImagePreview v-for="img in data.moreImg" :key="img" class="more_img" :src="require('../../assets/Talents/develop'+img)" :srcList="[require('../../assets/Talents/develop'+img)]"></ImagePreview> -->
        <div class="right">
            <el-button type="primary" @click="goBack">返回列表</el-button>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
        }
    },
    computed:{
        // data(){
        //     // return this.$store.state.developData.filter(item=>item.id==1)[0]
        // }
    },
    methods:{
        goBack(){
            this.$store.commit("set_path",{path:'/talents/develop',query:{}})
        }
    }
}
</script>
<style scoped>
.main{
    width:80vw;
    max-width:1600px;
    margin:0 auto;
}
.more_img{
    max-width:600px;
    margin:0 auto;
    margin-bottom:2rem;
}
.right{
    text-align: right;
    margin-bottom: 2rem;
}
</style>